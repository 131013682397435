import type { FormProps } from "@remix-run/react";
import { Form as RemixForm } from "@remix-run/react";
import type { FormEvent } from "react";
import { forwardRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function findAnalyticsActionName(
  { actionNameAttribute }: { actionNameAttribute: string },
  event: FormEvent<HTMLFormElement>,
): string {
  let submitter = (event.nativeEvent as SubmitEvent).submitter;
  if (!submitter) {
    submitter = event.currentTarget.querySelector("[type=submit]");
  }
  if (submitter) {
    if (submitter.hasAttribute(actionNameAttribute)) {
      return submitter.getAttribute(actionNameAttribute) as string;
    } else if (submitter.title) {
      return submitter.title;
    } else if (submitter.textContent) {
      return submitter.textContent;
    }
  }
  return event.currentTarget.action;
}

export const KsocForm = forwardRef<HTMLFormElement, FormProps>(
  ({ onSubmit, ...other }, ref) => {
    return (
      <RemixForm
        ref={ref}
        onSubmit={(event) => {
          if (onSubmit) {
            return onSubmit(event);
          }
        }}
        {...other}
      />
    );
  },
);

KsocForm.displayName = "KsocForm";
